import { initDoubleClick } from './header';
import { contactSubmit } from './contact.js';
import { AutomatitCarousel } from './automatit_carousel.m';
import { initHomeCaro } from './home';
import { initMultiMap } from './multiMap';
import { initFacNearbyMap, initRateFilters } from './facility';

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'message']
	});
}

if (document.getElementById('mailbox_form')) {
	contactSubmit({
		elementSelector: '#mailbox_form',
		required: ['name', 'email', 'mailbox', 'message']
	});
}
// Header Phone Hit
if(document.getElementById('head_phone')){
	document.getElementById('head_phone').addEventListener('click', function(){
		document.getElementById('header_phone_popup_wrap').classList.add('active');
	});
	document.getElementById('header_close').addEventListener('click', function(){
		document.getElementById('header_phone_popup_wrap').classList.remove('active');
	});
}
// Mobile menu open
document.getElementById('mobile_hammie').addEventListener('click', function(){
	document.getElementById('container').classList.toggle('mobile_menu_open');
});
// Double tapper
if(document.querySelector('.d_click')) {
	initDoubleClick();
}
// Hero carousel
if(document.querySelector('#home_hero_caro')) {
	initHomeCaro();
}
// Home Map
if(document.querySelector('#multi_map')) {
	initMultiMap();
}
// Contact Page Map
if(document.querySelector('#contact_page_map')) {
	initMultiMap();
}
if(document.querySelector('#facility_nearby_map')) {
	initFacNearbyMap();
}
// Home Tabbed nav
if(document.getElementById('tabbed_headers')){
	setTimeout(function(){
		document.getElementsByClassName('home_location_img_wrap')[0].classList.add('active');
		document.getElementsByClassName('home_location_button')[0].classList.add('active');
		document.getElementsByClassName('home_location_content')[0].classList.add('active');
	}, 100);
	document.getElementById('tabbed_headers').addEventListener('click', function(e){
		var tabs = null;
		var pages = null;
		var targetPage = null;
		if(e.target && e.target.nodeName === 'LI'){
			tabs = document.querySelectorAll('#tabbed_headers li');
			pages = document.querySelectorAll('.home_tabbed_item');
			targetPage = e.target.dataset.tab;

			[].forEach.call(tabs, function(el){
				if(el.classList.contains('active')){
					el.classList.remove('active');
				}
			});
			e.target.classList.add('active');

			[].forEach.call(pages, function(el){
				if(el.classList.contains('active')){
					el.classList.remove('active');
				}
			});
			document.getElementById("home_location_"+targetPage).classList.add('active');
			document.getElementById("home_location_img_"+targetPage).classList.add('active');
		}
	});
}

// Facility Carousel
if(document.getElementById('facility_carousel')){
	let caroOptions = {
		element: document.getElementById('facility_carousel'),
		images: window.facilityCarousel,
		useChevrons: false,
		showDots: true
	};

	if(window.facilityCarousel.length === 1) {
		caroOptions = {
			...caroOptions,
			intervalTiming: false,
			showDots: false
		};
	}
	AutomatitCarousel(caroOptions);
}

//Accordion styling for Tips
if (document.getElementById('tips_items_wrap')) {
	setTimeout(function(){
		document.getElementsByClassName('tips_item_wrap')[0].classList.add('active');
	}, 500);
	const helpItems = document.querySelectorAll('.tips_item_wrap');
	document.getElementById('tips_items_wrap').addEventListener('click', function(e) {
		if(e.target && e.target.nodeName === "H3") {
			let el = e.target.parentElement;
			if(el.classList.contains('active')) {
				el.classList.remove('active');
			}
			else {
				helpItems.forEach(i => {
					i.classList.remove('active');
				});
				el.classList.add('active');
			}
		}
	});
}

// Facility Tabs
if(document.getElementById('facility_tabs')){
	window.triggerResize = false;
	const tabs = document.querySelectorAll('#facility_tabs li');
	const pages = document.querySelectorAll('.facility_tab_content');
	document.getElementById('facility_tabs').addEventListener('click', function(e){
		if(e.target && e.target.nodeName === 'LI'){
			const targetPage = e.target.dataset.tab;

			[].forEach.call(tabs, function(el){
				if(el.classList.contains('active')){
					el.classList.remove('active');
				}
			});
			e.target.classList.add('active');
			window.triggerResize = true;

			[].forEach.call(pages, function(el){
				if(el.classList.contains('active')){
					el.classList.remove('active');
				}
			});
			document.getElementById("facility_"+targetPage).classList.add('active');
			if(window.innerWidth < 750) {
				// eslint-disable-next-line no-undef
				Velocity(document.querySelector('#facility_page_content'), 'scroll');
			}
		}
	});

	const pageURL = new URL(window.location.href);

	if(pageURL.hash.length) {
		const targetPage = pageURL.hash.slice(1);
		const targetEl = document.querySelector(`#facility_tabs li[data-tab='${targetPage}']`);
		[].forEach.call(tabs, function(el){
			if(el.classList.contains('active')){
				el.classList.remove('active');
			}
		});
		targetEl.classList.add('active');
		window.triggerResize = true;

		[].forEach.call(pages, function(el){
			if(el.classList.contains('active')){
				el.classList.remove('active');
			}
		});
		document.querySelector(`#facility_${targetPage}`).classList.add('active');
		document.querySelector(`#facility_${targetPage}`).scrollIntoView();
	}
}

if(document.querySelector('#fac_info_toggle')) {
	document.querySelector('#fac_info_toggle').addEventListener('click', () => {
		document.querySelector('#fac_info_toggle').classList.toggle('open');
		document.querySelector('#facility_top_content').classList.toggle('open');
	});
}

if(document.querySelector('#rates_filters')) {
	initRateFilters();
}