// Double Tap functionality
export const initDoubleClick = () => {
	document.querySelectorAll('.d_click').forEach(i => {
		i.addEventListener('click', e => {
			if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
				if(!i.hasAttribute('clicked') && window.innerWidth > 750) {
					e.preventDefault();
					i.setAttribute('clicked', true);
				}
			}
		});
	});
};