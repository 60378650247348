/* eslint-disable no-undef */
import { getMapsHelper } from './util/mapsHelper';

export const initMultiMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready().then(() => {
		const {map: theMap, bounds} = mapHelper.createMap({
			element: document.querySelector('#multi_map'),
			locationElementSelector: '.data_source',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `
						<a class="map_pin" href="${el.getAttribute('link')}">
							<img src="/static/images/icons/map_pin_red.svg" alt="Visit facility" />
						</a>
					`
				};
			},
		});

		google.maps.event.addListenerOnce(theMap, "idle", () => {
			theMap.setZoom(17);
		});

		theMap.addListener('bounds_changed', () => {
			if(window.triggerResize) {
				theMap.fitBounds(bounds);
				window.triggerResize = false;
			}
		});
	});
};