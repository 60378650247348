import { AutomatitCarousel } from './automatit_carousel.m';

// Home carousel
export const initHomeCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#home_hero_caro'),
		showDots: true,
		intervalTiming: 5000,
		useChevrons: false,
		autoVerticalCentering: false,
	});
};